export function getUserImgOrFallback(img: string | null): string {
  return img ?? '/placeholders/user.png'
}

export function getArticleImgOrFallback(img: string | null): string {
  return img ?? '/placeholders/article.png'
}

export function getPosterImgOrFallback(img: string | null): string {
  return img ?? '/placeholders/poster.png'
}

export function getPromoImgOrFallback(img: string | null): string {
  return img ?? '/placeholders/promo-image.png'
}
